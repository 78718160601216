import React from 'react'
import { Section, HrStyles, HR } from '../styles/common/Common';
import Map from '../components/Map';
import Hero from '../components/Hero';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import ContactForm from '../components/Contact/Contactform';
import { AlignCenter, BreadcrumbWrapper, Divider, IconWrapper, Item, ItemWrapper } from '../styles/common/Element';
import SEO from '../components/SEO';
import mq from '../styles/media-queries';
import { Col, Container, Row } from '../styles/common/Layout';
import { Breadcrumb } from 'gatsby-plugin-breadcrumb/components';


const FormStyles = styled.form`
  line-height: 1.4;
`

const H1 = styled.h1`
    margin-top: 4rem;
    text-transform: uppercase;
    color: var(--darkgrey);
    font-size: 2.3rem;
    font-weight: bold;
`

const ContactItem = styled(Item)`
  order: 2;
  ${mq.md} {
    order: 1;
  }
`

const ContactFormItem = styled(Item)`
  padding: 15px 0px 30px;
  order: 1;
  ${mq.md} {
    padding: 15px 30px 30px;
    order: 2;
  }
`

export default function KontaktPage({ pageContext, data }) {
  const {
    breadcrumb: { crumbs },
  } = pageContext
  return (
    <>
      <SEO
        title={"Kontakt"}
      />
      <Hero slug={'startseite'} small>
        <h1 className="headline">Kontakt</h1>
        <h2 className="sub-headline">Gerne sind wir Ihnen behilflich</h2>
      </Hero>
      <Container as="section">
        <Row>
          <Col>
            <BreadcrumbWrapper>
              <Breadcrumb crumbs={crumbs} crumbLabel={'Kontakt'} />
            </BreadcrumbWrapper>
          </Col>
        </Row>
        <Row>
          <Col>
            <AlignCenter>
              <h2>Was können wir für Sie tun?</h2>
              <Divider />
              <p>Schreiben Sie uns einfach eine Mail über das Formular oder nutzen Sie die Kontaktmöglichkeiten auf der Seite weiter unten. <br />Wir werden alle Anfragen schnellstmöglich beantworten.</p>
            </AlignCenter>
          </Col>
        </Row>
        <Row>
          <ItemWrapper>
            <ContactItem size={'35%'}>
              <AlignCenter>
                <IconWrapper>
                  <FontAwesomeIcon icon={faPhoneAlt} size="3x" />
                </IconWrapper>
                <h3>Rufen Sie uns an</h3>
                <a href="tel:+49-02865-2026291">
                  <span>+49-02865-2026291</span>
                </a>
              </AlignCenter>
              <HR />
              <AlignCenter>
                <IconWrapper>
                  <FontAwesomeIcon icon={faEnvelope} size="3x" />
                </IconWrapper>
                <h3>Schreiben Sie uns eine Mail</h3>
                <a href="mailto:info@physiotherapie-dumpe.de">
                  <span>info@physiotherapie-dumpe.de</span>
                </a>
              </AlignCenter>
            </ContactItem>
            <ContactFormItem size={'65%'}>
              <H1>Schreiben Sie uns eine Nachricht.</H1>
              <ContactForm form="contact" />
            </ContactFormItem>
          </ItemWrapper>
        </Row>
      </Container>
      <Map />
    </>
  )
}
